import { MailOption, Phone } from 'grommet-icons'
import {ReactComponent as WhatsApp} from './vectors/whats_app.svg'
import Index from './pages/index';
import Contact from './pages/contato';
import About from './pages/sobre' 

export const prefix = 
    (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    ? ''
    : '/web';

export const menu = [
    { element: Index, label: "Início", href: '/', visible: true },
    { element: Index, label: "Início", href: '/web', visible: false },
    { element: About, label: "Sobre o Sistema", href: prefix + '/sobre', visible: true },
    // { label: "Preços", href: '/planos', visible: true },
    { element: Contact, label: "Contato", href: prefix + '/contato', visible: true },
]

export const contactItems = [
    {
        icon: MailOption,
        text: "cella@tudoem.com.br",
        href: 'mailto:cella@tudoem.com.br',
        type: 'email'
    },
    {
        icon: MailOption,
        text: "cella@redcomet.com.br",
        href: 'mailto:cella@redcomet.com.br',
        type: 'email'
    },
    {
        icon: Phone,
        text: '12 9 8246-4587',
        href: 'https://api.whatsapp.com/send?1=pt_BR&phone=5512982464587&text=Olá desejo saber mais sobre o sistema Cella.',
        type: 'phone'
    },
    {
        icon: WhatsApp,
        text: '71 9 9954-3300',
        href: 'https://api.whatsapp.com/send?1=pt_BR&phone=5571999543300&text=Olá desejo saber mais sobre o sistema Cella.',
        type: 'phone'
    },
    {
        icon: WhatsApp,
        text: '71 9 9959-1466',
        href: 'https://api.whatsapp.com/send?1=pt_BR&phone=5571999591466&text=Olá desejo saber mais sobre o sistema Cella.',
        type: 'phone'
    },
]

export const companyAddress = {
    coord: [-23.806619324512, -45.362001618834],
    address: "Rua Lincon Feliciano, 610, Pereque - Ilhabela-SP"
}

export const masks = {
    phone: '(00) 0 0000-0000',
    tel: '(00) 0000-00000',
    cpf: '000.000.000-000',
    cnpj: '00.000.000/0000-00',
}