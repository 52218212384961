import React, { useContext } from 'react'
import { Grid, ResponsiveContext, Box, Text, List, Anchor } from 'grommet'
import { Phone, MailOption, FormNext, Send } from 'grommet-icons'
import { contactItems } from '../constants'
import ContactForm from '../components/contactForm'
import generalStyles from '../general.module.scss'
import ContactMap from '../components/contactMap'

function ContactPageBody(props) {
    const viewport = useContext(ResponsiveContext)

    const renderGridArea = () => {
        if (!viewport.includes('small')) {
            return [
                ['message', 'phones'],
                ['message', 'emails'],
                ['map', 'map']
            ]
        } else {
            return [
                ['message'],
                ['phones'],
                ['emails'],
                ['map']
            ]
        }
    }

    const renderGridColumns = () => {
        if (!viewport.includes('small')) {
            return ['1fr', '300px']
        } else {
            return ['1fr']
        }
    }
    return (
        <Grid
            areas={renderGridArea()}
            columns={renderGridColumns()}
            className={generalStyles.container}
            gap="medium"
        >
            <Box
                gridArea="phones"
                round="small"
                elevation="medium"
                height={"100%"}
                width={"100%"}
                pad="small"
                background="secondary"

            >
                <Box
                    direction='row'
                    pad={{ horizontal: 'xsmall', vertical: 'small' }}
                    align='center'
                    border={{
                        side: 'bottom',
                        color: 'rgba(255,255,255,.25)',
                        style: 'solid',
                        size: "xsmall"
                    }}
                >
                    <Phone color="white" />
                    <Text margin={{ left: 'xsmall' }} color="white">WhatsApp e Ligações</Text>
                </Box>
                <Box pad="small">
                    <List data={contactItems.filter(ci => ci.type === 'phone')} border={false} pad={{ vertical: "xxsmall" }}>
                        {datum => <Box direction={'row'} gap="small" align='center' >
                            <FormNext color="white" />
                            <Anchor href={datum.href} target="_blank">{datum.text}</Anchor>
                        </Box>}
                    </List>
                </Box>
            </Box>
            <Box
                gridArea="emails"
                round="small"
                elevation="large"
                height={"100%"}
                width={"100%"}
                pad="small"
                background="third"
            >
                <Box
                    direction='row'
                    pad={{ horizontal: 'xsmall', vertical: 'small' }}
                    align='center'
                    border={{
                        side: 'bottom',
                        color: 'rgba(255,255,255,.25)',
                        style: 'solid',
                        size: "xsmall"
                    }}
                >
                    <MailOption color="white" />
                    <Text margin={{ left: 'xsmall' }} color="white">E-mails</Text>
                </Box>
                <Box pad="small">
                    <List data={contactItems.filter(ci => ci.type === 'email')} border={false} pad={{ vertical: "xxsmall" }}>
                        {datum => <Box direction={'row'} gap="small" align='center' >
                            <Anchor href={datum.href} target="_blank">{datum.text}</Anchor>
                        </Box>}
                    </List>
                </Box>
            </Box>
            <Box
                gridArea="message"
                round="small"
                height={"100%"}
                width={"100%"}
                pad="small"
            >
                <Box
                    direction='row'
                    pad={{ horizontal: 'xsmall', vertical: 'small' }}
                    align='center'
                    border={{
                        side: 'bottom',
                        color: 'rgba(255,255,255,.25)',
                        style: 'solid',
                        size: "xsmall"
                    }}
                >
                    <Send color="jet" />
                    <Text margin={{ left: 'xsmall' }} color="jet">Envie Uma Mensagem</Text>
                </Box>
                <Box>
                    <ContactForm message={props.message}/>
                </Box>
            </Box>
            <Box
                gridArea="map"
                round="small"
                elevation="large"
                height={"400px"}
                width={"100%"}
                overflow="hidden"
            >
                {
                    typeof window !== 'undefined' &&
                    <ContactMap />
                }
            </Box>
        </Grid >
    )
}

export default ContactPageBody