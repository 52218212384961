import React from "react"
import { Box } from "grommet"

import Layout from "../layouts/layout"
import HeroHome from "../sections/HeroHome"
import KeyFunction from "../sections/KeyFunction"
import Features from '../sections/Features'
import MobilePresence from "../sections/MobilePresence"
import DesktopPresentation from "../sections/DesktopPresentation"
import Faq from '../sections/Faq'
import Contact from "../sections/Contact"
import Footer from "../sections/Footer"

import generalStyles from '../general.module.scss'

const IndexPage = ({location}) => (
  <Layout>
    <HeroHome />
    <Box as="section" className={generalStyles.container}>
      <KeyFunction location={location} />
    </Box>
    <Features />
    <Box as="section" className={generalStyles.container}>
      <MobilePresence />
    </Box>
    <DesktopPresentation />
    <Faq />
    <Box as="section" className={generalStyles.container}>
      <Contact />
    </Box>
    <Footer />
  </Layout>
)

export default IndexPage
//S.D.G.