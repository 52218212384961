import React, { useContext } from 'react'
import { Box, Grid, ResponsiveContext, Text, Paragraph } from 'grommet'
import { setViewportTerm } from '../utils';
import SmartphoneCards from '../components/smartphoneCards';

function MobilePresence() {

  const viewport = useContext(ResponsiveContext)

  const renderResponsiveGrid = () => {
    if (viewport.includes('small')) {
      return ['1fr']
    } else {
      return ['1.2fr', '1fr']
    }
  }

  return (
    <Box>
      <Grid
        columns={renderResponsiveGrid()}
        gap={'large'}
        margin={{ bottom: viewport.includes('small') ? 'medium' : 'large' }}
        align="center"
      >
        <Box align='center' justify='center'>
          <SmartphoneCards />
        </Box>
        <Box>
          <Text
            as={'h3'}
            size={setViewportTerm(viewport, 'large')}
            color="primary"
            align="center"
            textAlign='center'
          >
            Aplicativo Móvel Para Acompanhamento e Entregas.
          </Text>
          <Paragraph size={setViewportTerm(viewport, 'large')} textAlign="center">
            Seus entregadores ficarão sabendo de todas as rotas de entrega ativas e poderão realizar pedidos. Pelo app é possível deixar as informações destes sempre atualizadas.
          </Paragraph>
        </Box>
      </Grid>
    </Box>
  )
}

export default MobilePresence