import React, { useContext } from 'react'
import {
    Box,
    Text,
    ResponsiveContext,
    Paragraph,
    ThemeContext,
    Grommet,
    Anchor
} from 'grommet'
import { deepMerge } from 'grommet/utils';
import { setViewportTerm } from '../utils';
import generalStyles from '../general.module.scss'
import {ReactComponent as Logo} from '../vectors/Logo.svg'


function AboutContent() {

    const viewport = useContext(ResponsiveContext);
    const theme = useContext(ThemeContext)
    const customTheme = {
        paragraph: {
            medium: {
                maxWidth: viewport.includes('small') ? '100%' : '70%',
            },
        }
    }

    return (
        <Box className={generalStyles.container}>
            <Box>
                <Grommet theme={deepMerge(theme, customTheme)}>
                    <Box
                        wrap={true}
                        direction="row"
                        background="light-2"
                        pad="large"
                        round="medium"
                    >
                        <Box>
                            <Text
                                as={'h2'}
                                size={setViewportTerm(viewport, 'large')}
                                color="primary"
                                align="start"
                                textAlign='start'
                                margin={{ vertical: 'none', bottom: 'medium' }}
                            >
                                <Text
                                    color="secondary"
                                    size={setViewportTerm(viewport, 'large')}
                                >O que é o</Text> Cella<Text
                                    color="secondary"
                                    size={setViewportTerm(viewport, 'large')}
                                >?</Text>
                            </Text>
                            <Paragraph>
                                O Cella é um ERP de gestão de vendas, estoque e distribuição, Ele permite que sua empresa faça entrega de múltiplos produtos
                                apresentando a melhor rota possível, mesmo que eles estejam em depósitos e filiais diferentes. Tudo isso em uma interface
                                clara e fácil de usar, pensada para possibilitar uma curta curva de aprendizado.
                            </Paragraph>
                            <Paragraph>
                                Com um sistema de estoque robusto e em contante evolução sua empresa poderá fazer o controle dos seus produtos de uma forma mais
                                intuitiva.
                            </Paragraph>
                            <Paragraph>
                                Possui empresas em diferentes endereços ou várias filiais? Com o Cella você pode fazer com que elas trabalhem juntas até onde
                                desejar. Tenha um controle geral e compartilhado entre depósitos e filiais dos seus clientes, produtos, fornecedores, entre outros. Gerencie
                                 tudo em um lugar só definindo cargos e credênciais (ou funções) que cada um de seus funcionarios poderão ter.
                            </Paragraph>
                        </Box>

                    </Box>
                    <Box
                        wrap={true}
                        direction="row"
                        pad="large"
                    >
                        <Box>
                            <Text
                                as={'h2'}
                                size={setViewportTerm(viewport, 'large')}
                                color="primary"
                                align="start"
                                textAlign='start'
                                margin={{ vertical: 'none', bottom: 'medium' }}
                            >
                                Quem Somos?
                            </Text>
                            <Paragraph>
                                O Cella é fruto de uma parceria entre duas empresas de market e desenvolvimento, o <Anchor href="https://ilhabela.tudoem.com.br" target="_blank" color="primary">Tudoem</Anchor> e a <Anchor href="https://redcomet.com.br" target="_blank" color="primary">RedComet</Anchor>.
                            </Paragraph>
                            <Paragraph>
                                Presente no mercado a mais de 15 anos, o sistema Tudoem está implantado em 6 importantes cidades e tem 3 franquias. 
                                O mega portal reúne toda a informação relevante de cada cidade de maneira prática e inteligente, ele não é apenas um 
                                sistema de mídia, mas um portal de negócios.
                            </Paragraph>
                            <Paragraph>
                                A RedComet é uma empresa de desenvolvimento de softwares e identidade visual com uma equipe experiênte, tendo construído, 
                                projetado ou participado do desenvolvimento de sistemas de automação aduaneira, vendas, georreferenciamento, gestão de
                                planos de saúde, marketplaces, websites, plugins, entre outros.
                            </Paragraph>
                        </Box>

                    </Box>
                </Grommet>
            </Box>
            <Box width={"300px"} margin={{ vertical: "medium" }} alignSelf="end">
                <Logo />
            </Box>
        </Box>

    )
}

export default AboutContent