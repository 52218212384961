import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Default from '../layouts/default'
import Layout from '../layouts/layout'
import ContactPageBody from '../sections/ContactPageBody'
import toast from 'react-hot-toast';


function Contato() {
    const location = useLocation()
    useEffect(() => {
        if (location && location.state && location.state.toast) {
            toast(location.state.toast);
        }
    }, [location])

    return (
        <Layout title="Contato | Cella">
            <Default
                header="Contato"
                commentHeader="Fale Conosco"
            >
                <ContactPageBody message={location && location.state && location.state.message} />
            </Default>
        </Layout>
    )
}

export default Contato
//S.D.G.