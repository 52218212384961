import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Box, Text, ResponsiveContext, Paragraph, Button } from 'grommet'
import Lottie from 'react-lottie';
import { setViewportTerm } from '../utils';
import * as contactMailAnimation from '../lottie/contact-mail.json'
import { prefix } from '../constants'

function Contact() {

    const viewport = useContext(ResponsiveContext);

    const renderResponsiveGrid = () => {
        if (viewport.includes('small')) {
            return ['1fr']
        } else {
            return ['1.5fr', '1fr']
        }
    }

    const contactMailAnimationOptions = {
        loop: true,
        autoplay: true,
        animationData: contactMailAnimation,
    }

    return (
        <Grid
            columns={renderResponsiveGrid()}
            margin={{ vertical: 'small' }}
            align="center"
        >
            <Box align='center'>
                <Text
                    as='h2'
                    size={setViewportTerm(viewport, "xlarge")}
                    color="primary"
                    textAlign='center'
                    margin={{ horizontal: 'auto', vertical: 'none', bottom: 'medium' }}
                >
                    Conheça o sistema. Contate-nos
                </Text>
                <Paragraph size={setViewportTerm(viewport, 'large')} textAlign="center">
                    Contrate o ERP Cella! Para mais informações sobre as funcionalidades do sistema; contratação, acesso e o que desejar, entre em contato conosco.
                </Paragraph>
                <Grid
                    columns={viewport === 'small' ? ['1fr'] : ['1fr', '1fr']}
                    gap={'1em'}
                    width={{ max: '100%' }}
                    margin={{ horizontal: 'auto' }}
                >
                    <Link to={prefix + "/contato"} state={{ type: "buy-sistem", message: "Olá, desejo mais informações sobre preços, planos e aquisição!", toast: "Envie uma mensagem e nós rapidamente entraremos em contato com você sobre aquisição do sistema." }}>
                        <Button label="Adquira o Cella" primary color="success" margin={{ vertical: 'small' }} style={{ textAlign: 'center' }} />
                    </Link>
                    <Link to={prefix + "/contato"}>
                        <Button label="Contate-nos" primary color="primary" margin={{ vertical: 'small' }} style={{ textAlign: 'center' }} />
                    </Link>
                </Grid>
            </Box>
            <Box>
                <Lottie
                    options={contactMailAnimationOptions}
                />
            </Box>
        </Grid>
    )
}

export default Contact