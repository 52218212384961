import React from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import { menu } from './constants'
import Page404 from './pages/404'


const router = createBrowserRouter([
  {
    path: '*',
    element: <Page404 />
  },
  ...menu.map(d => {
    return {
      path: d.href,
      element: <d.element />,
    }
  }),
]);

export {
  router
}