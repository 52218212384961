import bottomWave from './assets/svg/bottom_wave.svg'
import waves from './assets/svg/Waves.svg'
import wareImage from './assets/images/ware_photo.webp'
const defaultTheme = {
  global: {
    backgrounds: {
      light: 'light-1',
      'light-2': 'dirtGray',
      'light-blue': 'gostWhite',
      'light-blue-2': 'aliceblue',
      'linear-diagonal-black-gradient': 'linear-gradient(132deg, rgba(34,35,40,1) 0%, rgba(44,46,52,1) 27%, rgba(43,43,50,1) 47%, rgba(37,38,43,1) 70%, rgba(44,46,52,1) 100%);',
      'linear-diagonal-white-gradient': 'linear-gradient(132deg, rgba(255,255,255,1) 0%, rgba(228,228,228,1) 20%, rgba(255,255,255,1) 45%, rgba(242,242,242,1) 70%, rgba(231,231,231,1) 100%);',
      basic: {
        light: 'light-1',
        dark: {
          color: 'jet',
        }
      },
      "primary-gradient": {
        color: 'primary',
        image: 'radial-gradient(circle farthest-corner at 90% -22%, rgba(200, 200, 200, 0.21), TRANSPARENT 85%);',
      },
      primary: 'primary',
      heroHome: {
        color: 'primary',
        image: `
          url(${bottomWave}),
          url(${waves}),
          radial-gradient(circle farthest-corner at 90% -22%, rgba(200, 200, 200, 0.16), TRANSPARENT 85%)
        `
      },
      'primary-image': {
        color: 'primary',
        image: `
          url(${bottomWave}),
          url(${waves}),
          radial-gradient(circle farthest-corner at 90% -22%, rgba(200, 200, 200, 0.16), TRANSPARENT 85%),
          linear-gradient(0.3turn, rgba(30, 97, 174,.8), rgba(30, 97, 174,.8)),
          url(${wareImage})
        `
      },
    },
    elevation: {
      light: {
        none: 'none',
        xsmall: '0px 1px 2px rgba(32, 32, 32, .1)',
        small: '0px 2px 4px rgba(32, 32, 32, 0.1)',
        medium: '0px 4px 8px rgba(32, 32, 32, 0.1)',
        large: '0px 8px 16px rgba(32, 32, 32, 0.20)',
        xlarge: '0px 12px 24px rgba(32, 32, 32, 0.20)',
      }
    },
    edgeSize: {
      xxlarge: '146px',
      xxxlarge: '212px',
      'r-medium': '5vw',
      'r-large': '10vw',
      // VIEWPORT SIZES ->

      // MEDIUM-SMALL
      'medium-small-r-medium': '3vw',
      'medium-small-r-large': '6vw',
      // MEDIUM
      'medium-r-medium': '2vw',
      'medium-r-large': '4vw',
      // MEDIUM-LARGE
      'medium-large-r-medium': '3vw',
      'medium-large-r-large': '6vw',

    },
    breakpoints: {
      small: {
        value: 500,
        edgeSize: {
          xxxlarge: '120px',
          xxlarge: '100px',
          'r-medium': '1vw',
          'r-large': '4vw',
        },
      },
      'medium-small': {
        value: 767,
      },
      medium: {
        value: 1020
      },
      'medium-large': {
        value: 1200
      }
    },
    colors: {
      'light-1': '#FFF',
      'light-2': '#F3F6FB',
      'light-3': '#DEE1E5',
      success: '#39C658',
      primaryLight: '#52A3F9',
      primary: '#2B7DDB',
      secondary: '#3EB595',
      secondaryLight: '#6BCCB2',
      third: '#E08257',
      thirdLight: '#FFA074',
      'text-light': '#FFF',
      'text-dark': '#363537',
      purple: '#7208DB',
      jet: '#363537',
      gray: '#4F4F4F',
      dirtGray: 'rgba(51,51,51,0.04)',
      placeholder: 'rgba(51,51,51,0.32)',
      raisinBlack: '#1E1F23',
      platinum: '#E6E6E6',
      gostWhite: '#F3F6FB',
      gainsboro: '#DEDEDE',
      aliceblue: '#E8EEF7'
    },
    font: {
      family: 'Lexend Deca',
    },
    control: {
      border: {
        radius: '10px'
      }
    }
  },
  // COMPONENTS
  anchor: {
    color: {
      light: 'text-light',
      dark: '#363537'
    },
    extend: `font-family: 'Roboto';letter-spacing: 0.5px;`,
    fontWeight: 300,
    size: {
      'r-medium': '1.2vw'
    }
  },
  paragraph: {
    // SMALL DEVICE SIZES
    'small-large': {
      size: '16px',
      height: '20px'
    },
    // DEFAULT
    small: {
      size: '14px',
      height: '30px'
    },
    large: {
      size: '22px',
      height: '32px',
      maxWidth: 667
    },
    extend: `
      font-weight: 300
    `
  },
  button: {
    border: {
      radius: '10px',
      color: '#FFF',
      width: '1px'
    },
    primary: {
      extend: `
        color: #FFF;
        box-shadow: 0 2px 4px 0 rgb(32 32 32 / 14%);
      `
    },
    extend: `
      font-family: 'Roboto';
      font-weight: bold;
      font-size: 16px;
      white-space: nowrap
    `
  },
  text: {
    // SMALL DEVICE SIZES
    'small-small': {
      size: '12px',
      height: '15px',
    },
    'small-regular': {
      size: '18px',
      height: '23px'
    },
    'small-large': {
      size: '28px',
      height: '35px'
    },
    'small-xlarge': {
      size: '32px',
      height: '40px',
    },
    'small-xxlarge': {
      size: '40px',
      height: '45px',
    },
    // LARGE (DEFAULT) DEVICE SIZES
    xxsmall: {
      size: '14px',
      height: '30px',
    },
    xsmall: {
      size: '16px',
      height: '30px',
    },
    small: {
      size: '18px',
      height: '23px',
    },
    'medium-small': {
      size: '20px',
      height: '25px'
    },
    medium: {
      size: '22px',
      height: '32px',
    },
    regular: {
      size: '24px',
      height: '30px'
    },
    'medium-large': {
      size: '26px',
      height: '33px'
    },
    large: {
      size: '46px',
      height: '58px',
    },
    xlarge: {
      size: '56px',
      height: '70px',
    },
    xxlarge: {
      size: '64px',
      height: '64px',
    },
  },
  textInput: {
    extend: `
        background: #FFF;
        color: #363537;
      `
  },
  // ELEMENTS
  h1: {
    font: {
      family: 'Lexend Deca',
      height: '64px',
      size: '64px',
      weight: 'bold'
    }
  },
  h2: {
    font: {
      family: 'Lexend Deca',
      height: '70px',
      size: '56px',
      weight: 'bold'
    }
  },
  h3: {
    font: {
      family: 'Lexend Deca',
      height: '58px',
      size: '46px',
      weight: 'bold'
    }
  },
};


export {
  defaultTheme
}