import React, { createContext, useEffect } from 'react'

const initialContext = {
    mainScroll: null
}

const AppContext = createContext(initialContext)

const AppProvider = ({ children, ...props }) => {

    return (
        <AppContext.Provider value={{mainScroll: props.data.mainScroll}}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext;
export {
    AppProvider
}
