import React from 'react'
import { Box, Text, Paragraph } from 'grommet'

function FeatureCard(props) {

  return (
    <Box
      elevation='medium'
      background='light'
      pad="medium"
      round="medium"
    >
      <Box
        align="center"
      >
        {props.icon && <props.icon style={{height: '48px', width: 'auto'}} />}
      </Box>
      <Box>
        <Text
          textAlign='center'
          margin={{ vertical: 'small' }}
          weight={600}
          size="medium-large"
        >
          {props.title}
        </Text>
      </Box>
      <Box align='center'>
        <Paragraph
          textAlign='center'
          weight={300}
          size="small"
        >
          {props.description}
        </Paragraph>
      </Box>
    </Box>
  )
}

export default FeatureCard
//S.D.G.