import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import { companyAddress } from '../constants'


function ContactMap() {
    return (
        <MapContainer center={companyAddress.coord} zoom={17} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={companyAddress.coord}
                icon={L.icon({
                    iconUrl: 'images/map_marker.png',
                    iconAnchor: [15, 40],
                    popupAnchor: [0, -40],
                })}
            >
                <Popup>
                    {companyAddress.address}
                </Popup>
            </Marker>
        </MapContainer>
    )
}

export default ContactMap