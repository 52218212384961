import React, { useRef, useState, useEffect } from "react"
import produce from 'immer'
import PropTypes from "prop-types"
import { useScroll } from 'framer-motion'
import { Toaster } from 'react-hot-toast';
import { Grommet } from "grommet"
import { Helmet } from "react-helmet"
import { AppProvider } from '../contexts/appContext'
import { ResponsiveContext } from "grommet/contexts";
import { defaultTheme } from "../theme"

const Layout = ({ children, title, description }) => {
    const ref = useRef(typeof document !== "undefined" ? document.querySelector('#grommet-main') : null);
    const [mainScroll, setMainScroll] = useState({ x: 0, y: 0, percentX: 0, percentY: 0, scrollHeight: 0, ref: null })

    const { scrollYProgress, scrollY } = useScroll({
        container: ref
    })

    useEffect(() => {
        return scrollYProgress.onChange((latest) => {
            setMainScroll(produce(draft => {
                draft.percentY = latest
            }))
        })
    }, [])

    useEffect(() => {
        return scrollY.onChange((latest) => {
            setMainScroll(produce(draft => {
                draft.y = latest
            }))
        })
    }, [])

    useEffect(() => {
        if (ref) {
            setMainScroll(produce(draft => {
                draft.ref = ref.current
            }))
        }
    }, [])
    return (
        <Grommet
            theme={defaultTheme}
            full
            id="grommet-main"
            style={{ overflow: 'overlay' }}
            ref={ref}
        >
            <AppProvider data={{ mainScroll }}>
                <Helmet>
                    <title>{title || 'Cella | Sistema de Distribuição, Estoque e Logistica'}</title>
                    <meta name="description" content={description || "Sistema de estoque vendas com PDV, gestão e delivery inteligente"} />
                    <meta property="og:title" content={title || 'Cella | Sistema de Distribuição, Estoque e Logistica'} />
                    <meta property="og:description" content={description || "Sistema de estoque vendas com PDV, gestão e delivery inteligente"} />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:creator" content={`Red Comet`} />
                    <meta name="twitter:title" content={title || 'Cella | Sistema de Distribuição, Estoque e Logistica'} />
                    <meta name="twitter:description" content={description || "Sistema de estoque vendas com PDV, gestão e delivery"} />
                    <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.3/dist/leaflet.css"
                        integrity="sha256-kLaT2GOSpHechhsozzB+flnD+zUyjE2LlfWPgU04xyI="
                        crossorigin="" />
                </Helmet>
                <Toaster position="top-center" />
                <ResponsiveContext.Consumer>
                    {() => children}
                </ResponsiveContext.Consumer>
            </AppProvider>
        </Grommet>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout