import React, { useContext } from 'react'
import { Box, Text, Anchor, ThemeContext, Grommet, Grid, List, ResponsiveContext } from 'grommet'
import { deepMerge } from 'grommet/utils';
import { menu as navItems, contactItems } from '../constants'

import generalStyles from '../general.module.scss'
import styles from './footer.module.scss'
import {ReactComponent as TopWave} from '../vectors/bottom_wave.svg'
import {ReactComponent as TudoemLogo} from '../vectors/tudoem_logo.svg'
import {ReactComponent as RedCometLogo} from '../vectors/red_comet_logo.svg'
import {ReactComponent as LogoWhite} from '../vectors/Logo_white.svg';


function Footer() {

    const theme = useContext(ThemeContext);
    const viewport = useContext(ResponsiveContext)

    const menu = [
        ...navItems.filter(f => f.visible),
        { label: "Política de Privacidade", href: '/politica-de-privacidade' }
    ]

    const bottomTheme = {
        text: {
            font: {
                family: 'Roboto'
            },
            xxsmall: {
                height: '16px'
            },
        },
    };

    const renderResponsiveGrid = () => {
        if (viewport.includes('large')) {
            return ['auto', '1fr', '1fr']
        } else if (viewport === 'medium') {
            return ['1fr', '1fr']
        } else {
            return ['1fr']
        }
    }

    const renderResponsiveGridAreas = () => {
        if (viewport.includes('large')) {
            return [
                ["logos", "menu", "contact"]
            ]
        } else if (viewport === 'medium') {
            return [
                ["menu", "contact"],
                ["logos", "logos"]
            ]
        } else {
            return [
                ["contact"],
                ["menu"],
                ["logos"]
            ]
        }
    }

    return (
        <Box as="footer" background={"primary-gradient"}>
            <Grommet theme={deepMerge(theme, bottomTheme)}>
                <TopWave className={styles.interfaceWave} />
                <Box className={generalStyles.container}>
                    <Grid
                        columns={renderResponsiveGrid()}
                        margin={{ bottom: "medium" }}
                        gap="small"
                        areas={renderResponsiveGridAreas()}
                    >
                        <Box
                            border={viewport.includes('large') && {
                                side: 'right',
                                color: 'rgba(255,255,255,.25)',
                                style: 'solid',
                                size: "xsmall"
                            }}
                            margin={{ vertical: 'small' }}
                            gridArea="logos"
                        >
                            <Text size="xxsmall" margin={{ bottom: 'medium' }} >
                                Uma Parceria:
                            </Text>
                            <Box pad={{ horizontal: 'large' }} align="center">
                                <Anchor href="https://ilhabela.tudoem.com.br" target="_blank">
                                    <TudoemLogo />
                                </Anchor>
                                <Anchor href='https://redcomet.com.br' target="_blank">
                                    <RedCometLogo />
                                </Anchor>
                            </Box>
                        </Box>
                        <Box
                            gridArea='menu'
                            width={{ max: '250px' }}
                            margin={{ horizontal: 'auto', vertical: 'small' }}
                            justify="center"
                        >
                            <Text size="medium" margin={{ bottom: 'medium' }} >
                                Menu
                            </Text>
                            <List data={menu} pad={{ vertical: 'small' }} border={false}>
                                {datum => <Anchor href={datum.href}>{datum.label}</Anchor>}
                            </List>
                        </Box>
                        <Box
                            border={viewport.includes('large') && {
                                side: 'left',
                                color: 'rgba(255,255,255,.25)',
                                style: 'solid',
                                size: "xsmall"
                            }}
                            pad={{ horizontal: 'large' }}
                            gridArea="contact"
                            margin={{ vertical: 'small' }}
                        >
                            <Box
                                width={{ max: '400px' }}
                                margin={{ horizontal: 'auto' }}
                            >
                                <Box margin={{ bottom: 'small' }}>
                                    <Anchor href="/">
                                        <LogoWhite className={styles.cellaLogo} />
                                    </Anchor>
                                </Box>
                                <List data={contactItems} border={false} pad={{ vertical: "xxsmall" }}>
                                    {datum => <Box direction={'row'} gap="small" align='center' >
                                        <Anchor href={datum.href} target="_blank">
                                            <Box round="full" background={"light"} pad="small" className={styles.circleContactIcon}>
                                                <datum.icon color="primary" />
                                            </Box>
                                        </Anchor>
                                        <Anchor href={datum.href} target="_blank">{datum.text}</Anchor>
                                    </Box>}
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                    <Box
                        border={{
                            side: 'top',
                            color: 'rgba(255,255,255,.25)',
                            style: 'solid',
                            size: "xsmall"
                        }}
                        direction="row"
                        justify='between'
                        align='center'
                        pad="xsmall"
                        margin={{ vertical: "medium" }}
                        gap="xsmall"
                        wrap={true}
                    >
                        <Box width={{ min: '200px' }} pad={{ vertical: 'small' }}>
                            <Text
                                size="xxsmall"
                            >
                                Copyright@ 2022. All rights reserved.
                            </Text>
                        </Box>
                        <Box width={{ max: '400px', min: '300px' }} pad={{ vertical: 'small' }}>
                            <Text
                                size="xxsmall"
                                textAlign='center'
                                style={{ fontStyle: 'italic' }}
                            >
                                Porque o salário do pecado é a morte, mas o dom gratuito de Deus é a vida eterna - Rm 6:23
                            </Text>
                        </Box>
                        <Box width={{ min: '80px' }} pad={{ vertical: 'small' }}>
                            <Text
                                size="xxsmall"
                            >
                                Desenvolvido por <Anchor href='https://redcomet.com.br' target="_blank" weight="bold" size="xxsmall">
                                    RedComet.
                                </Anchor>
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Grommet>
        </Box>
    )
}

export default Footer