import React, { useContext } from 'react'
import { Box, Text, ResponsiveContext } from 'grommet'
import { setViewportTerm } from '../utils';
import Footer from '../sections/Footer'
import Nav from '../layouts/nav'

import generalStyles from '../general.module.scss'
import styles from './default.module.scss'

function Default(props) {

    let viewport = useContext(ResponsiveContext);

    return (
        <Box>
            <Box
                background="primary-image"
                className={styles.heroBackground}
            >
                <Nav />
                <Box
                    className={generalStyles.container}
                    margin={
                        {
                            vertical: 'large',
                            bottom: 'xlarge'
                        }
                    }
                >
                    {
                        props.commentHeader &&
                        <Text
                            size={"xsmall"}
                        >
                            {props.commentHeader}
                        </Text>
                    }
                    {
                        props.header &&
                        <Text
                            as="h1"
                            size={setViewportTerm(viewport, "xxlarge")}
                        >
                            {props.header}
                        </Text>
                    }
                </Box>
            </Box>
            <Box
                margin={{vertical: 'large'}}
            >
                {props.children}
            </Box>
            <Footer />
        </Box>
    )
}

export default Default