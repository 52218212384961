import * as React from "react"
import { Box } from 'grommet'
import Layout from "../layouts/layout"
import Default from '../layouts/default'
import Seo from "../components/seo"
import Lottie from 'react-lottie';
import * as animation404 from '../lottie/404.json'

import generalStyles from '../general.module.scss'


const NotFoundPage = () => {
  const animation404Option = {
    loop: true,
    autoplay: true,
    animationData: animation404,
  }

  return (
    <Layout>
      <Default>
        <Box className={generalStyles.container} direction="row" justify="evenly" wrap="reverse">
          <Box pad={{horizontal: "10%"}} align="center" justify="center">
            <h1>404: Not Found</h1>
            <p>A pagina não foi encontrada</p>
          </Box>
          <Box align="center" justify="center">
            <Lottie
              options={animation404Option}
              width={400}
            />
          </Box>
        </Box>
      </Default>
    </Layout>
  )
}

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
