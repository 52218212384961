import React, { useContext, useState, useRef, useEffect } from 'react';
import { Box, Header, Nav as GNav, Anchor, Button, ResponsiveContext, Layer } from 'grommet';
import { Link } from 'react-router-dom'
import { Close, FormNext } from 'grommet-icons';
import { ReactComponent as Logo } from '../vectors/Logo.svg';
import { ReactComponent as LogoWhite } from '../vectors/Logo_white.svg';
import { ReactComponent as Menu } from '../vectors/menu.svg';
import styles from "./nav.module.scss";
import generalStyles from '../general.module.scss'
import { sizeByViewport } from '../utils';
import { menu as navItems } from '../constants'

function Nav() {
  const viewport = useContext(ResponsiveContext);
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const win = useRef(typeof window !== "undefined" ? window.location.origin : null)

  const handleToggleMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu)
  }

  return (
    <Header
      direction='row'
      className={generalStyles.container}
      margin={
        {
          vertical: 'small'
        }
      }
    >
      <Box direction='row'>
        <Box pad={{
          right: 'large'
        }}>
          <Link to={navItems[0].href}>
            <LogoWhite className={viewport.includes('small') ? styles.logoSmall : styles.logoRegular} />
          </Link>
        </Box>
        {
          viewport !== 'medium' && !viewport.includes('small') &&
          <GNav align={'center'} direction='row'>
            {navItems.map((item, i) => item.visible && (
              <Link to={item.href} style={{textDecoration: 'none'}}>
                <Anchor key={i} as="span" label={item.label} color="text-light" />
              </Link>
            ))}
          </GNav>
        }
      </Box>
      {viewport !== 'medium' && !viewport.includes('small') &&
        <Box direction='row'>
          {/* <Box>
            <Button label="Login" margin={{
              horizontal: 'medium'
            }} />
          </Box> */}
          <Box margin={{ horizontal: 'medium' }}>
            {/*<a href={win.current + "/login"}>*/}
            {/*  <Button label="Login" style={{ textAlign: 'center' }} />*/}
            {/*</a>*/}
          </Box>

          {/* <Box>
            <Button color={'secondary'} primary label="Cadastre-se" />
          </Box> */}
          <Link to="/contato" state={{ type: "buy-sistem", message: "Olá, desejo ter uma demonstração do sistema Cella.", toast: "Envie uma mensagem solicitando uma demonstração" }}>
            <Button label="Peça uma Demonstração" primary color="secondary" style={{ textAlign: 'center' }} />
          </Link>
        </Box>
      }
      {!viewport.includes('large') &&
        <Box direction='row' align="right">
          <Button icon={<Menu size='medium' />} hoverIndicator size="large" onClick={handleToggleMobileMenu} />
        </Box>
      }
      {openMobileMenu &&
        <Layer full="horizontal" position="top" background="light">
          <Box fill className={styles.containerMobile}>
            <Box
              direction="row"
              align="center"
              as="header"
              justify="between"
              pad={
                {
                  horizontal: sizeByViewport(viewport, 'r-large'),
                  vertical: 'small'
                }
              }
            >
              <Logo className={styles.logoSmall} />
              <Button icon={<Close color="jet" />} hoverIndicator size="large" onClick={handleToggleMobileMenu} />
            </Box>
            <Box overflow="auto">
              <Box
                pad={
                  {
                    horizontal: 'large',
                    vertical: 'medium'
                  }
                }
              >
                {navItems.map((item, i) => item.visible && (
                  <>
                    <Link to={item.href} style={{textDecoration: 'none'}}>
                      <Anchor
                        key={i}
                        as="span"
                        label={item.label}
                        color="jet"
                        size='medium'
                        weight={500}
                        icon={<FormNext />}
                        className={styles.mobileMenuAnchor}
                        reverse
                        margin={{
                          vertical: 'medium'
                        }}
                      />
                    </Link>
                    <span className={styles.mobileMenuAnchorHr} />
                  </>
                ))}
              </Box>
              <Box
                pad={
                  {
                    horizontal: 'large',
                    vertical: 'medium'
                  }
                }
              >
                <Box direction='column'>
                  {/* <Box>
                    <Button label="Login" primary color="primary" margin={{ vertical: 'small' }} />
                  </Box> */}
                  <Box margin={{ vertical: 'small' }}>
                    {/*<a href={win.current + "/login"} style={{ width: '100%' }}>*/}
                    {/*  <Button label="Login" primary color="primary" style={{ textAlign: 'center', width: '100%' }} />*/}
                    {/*</a>*/}
                  </Box>
                  <Link to="/contato" style={{ width: '100%' }} state={{ type: "buy-sistem", message: "Olá, desejo ter uma demonstração do sistema Cella.", toast: "Envie uma mensagem solicitando uma demonstração" }}>
                    <Button label="Peça uma Demonstração" primary color="secondary" style={{ textAlign: 'center', width: '100%' }} />
                  </Link>
                  {/* <Box>
                    <Button color={'secondary'} primary label="Cadastre-se" margin={{ vertical: 'small' }} />
                  </Box> */}
                </Box>
              </Box>
            </Box>
          </Box>
        </Layer>
      }
    </Header>
  )
}

export default Nav