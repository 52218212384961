import React, { useContext } from 'react'
import { Box, ResponsiveContext, ThemeContext, Text, Accordion, AccordionPanel, Grommet } from 'grommet'
import { deepMerge } from 'grommet/utils';
import { Help, FormDown, FormUp } from 'grommet-icons';
import globalStyles from '../general.module.scss'
import styles from './faq.module.scss'
import { setViewportTerm } from '../utils';

const questions = [
    {
        question: 'Posso importar as informações do sistema que utilizo hoje no Cella?',
        answer: 'Sim. Você pode importar todos os clientes, produtos, categorias, marcas, armazéns, preços, unidades métricas e fornecedores. Para mais importações você deve entrar em contato com a nossa equipe para analisar a viabilidade.'
    },
    {
        question: 'Posso ter um acesso a uma demonstração do ERP?',
        answer: 'Sim. Insira seu email e clique em "Conheça o Sistema" na sessão de apresentação, no início desta página ou entre em contato conosco.'
    },
    {
        question: 'Cada filial pode possuir seu próprio CNPJ?',
        answer: 'Sim. Cada filial pode possuir seu próprio CNPJ e informações fiscais ou utilizar os mesmos da matriz.'
    },
    {
        question: 'Como posso resolver problemas em casos de erros com cartão de crédito?',
        answer: 'Envie um email ou entre em contato, também é possivel resolver na plataforma administrativa (em desenvolvimento)'
    },
    {
        question: 'Os planos não se encaixam com minha empresa. É possível obter um plano personalizado?',
        answer: 'Sim. Escolha a opção plano personalizado ou envie-nos um email descrevendo suas necessidades. Nossa equipe contactará você.'
    },
    {
        question: 'O sistema possui integração com marketplaces externos?',
        answer: 'Não. O sistema não se conecta a plataformas de marketplaces externas, mas pode ser integrado no futuro.'
    },
    {
        question: 'Preciso de uma funcionalidade específica, é possivel implementa-la?',
        answer: 'Depende. Entre em contato com nossa equipe e descreva a funcionalidade desejada, analizaremos a viabilidade então enviamos uma resposta.'
    },
]


function Faq() {


    const viewport = useContext(ResponsiveContext);
    const theme = useContext(ThemeContext);

    const faqTheme = {
        global: {
            font: {
                family: 'Lexend Deca, Roboto',
            },
        },
        heading: {
            weight: 400,
            extend: `
                white-space: break-spaces;
                max-width: 100%;
            `
        },
        accordion: {
            icons: {
                collapse: FormUp,
                expand: FormDown,
                color: 'primary',
            },
            border: {
                color: 'gainsboro'
            },
        },
    };
    

    return (
        <Box
            margin={{ vertical: 'large' }}
            background="light-blue"
            pad={{ vertical: 'xlarge' }}
            className={styles.container}
        >
            <Box
                className={`${globalStyles.container}`}
                margin={{ bottom: 'medium' }}
            >
                <Box
                    direction='row'
                    align="center"
                >
                    <Help
                        color='secondary'
                    />
                    <Text
                        color="secondary"
                        size={setViewportTerm(viewport, "small")}
                        margin={{
                            left: 'xsmall'
                        }}
                    >
                        FAQ
                    </Text>
                </Box>
                <Box>
                    <Text
                        as={'h2'}
                        size={setViewportTerm(viewport, 'large')}
                        color="primary"
                        align="start"
                        textAlign='start'
                        margin={{ vertical: 'none', bottom: 'medium' }}
                    >
                        Perguntas Frequentes
                    </Text>
                </Box>
                <Box>
                    <Grommet theme={deepMerge(theme, faqTheme)}>
                        <Accordion>
                            {questions.map(({ question, answer }) => {
                                return <AccordionPanel label={question}>
                                    <Box pad="medium" background="light-blue-2" round="medium" margin={{vertical: 'small'}}>
                                        <Text
                                            weight={300}
                                            size={setViewportTerm(viewport, 'small')}
                                        >{answer}</Text>
                                    </Box>
                                </AccordionPanel>
                            })}
                        </Accordion>
                    </Grommet>
                </Box>
            </Box>
        </Box>
    )
}

export default Faq