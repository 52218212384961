import React, { useContext } from 'react'
import { Box, Text, Paragraph, ResponsiveContext, Grid } from 'grommet'
import { Info } from 'grommet-icons';
import FeatureCard from '../components/featureCard'
import { setViewportTerm } from '../utils';
import { ReactComponent as TriangleRetangle } from '../vectors/triangle-retangle.svg'
import generalStyles from '../general.module.scss'
import { features } from './Features.data'

function Features() {
    const viewport = useContext(ResponsiveContext);

    const selectCardFeaturesColumn = () => {
        if (viewport.includes('small')) {
            return ['1fr']
        } else if (viewport === 'medium') {
            return ['1fr', '1fr']
        } else {
            return ['1fr', '1fr', '1fr']
        }
    }

    return (
        <Box as="section">
            <TriangleRetangle />
            <Box
                background="light-2"
            >
                <Box
                    className={`${generalStyles.container}`}
                    pad={'large'}
                >
                    <Box
                        pad={{ horizontal: 'medium' }}
                        margin={{ bottom: 'medium' }}
                    >
                        <Box
                            direction='row'
                            margin={{ horizontal: 'auto' }}
                            align="center"
                        >
                            <Info
                                color='third'
                            />
                            <Text
                                color="third"
                                size={setViewportTerm(viewport, "small")}
                                margin={{
                                    left: 'xsmall'
                                }}
                            >
                                Algumas Funcionalidades
                            </Text>
                        </Box>
                        <Text
                            as='h2'
                            size={setViewportTerm(viewport, "xlarge")}
                            color="primary"
                            textAlign='center'
                            margin={{ horizontal: 'auto', vertical: 'none', bottom: 'medium' }}
                        >
                            Saiba Mais do Que o <Text color="third" size={setViewportTerm(viewport, "xlarge")}>Cella</Text> Tem Para o Seu Negócio
                        </Text>
                        <Paragraph
                            textAlign='center'
                            alignSelf='center'
                            size={setViewportTerm(viewport, "large")}
                        >
                            Estamos a todo momento melhorando e adicionando funcionalidades que podem tornar o seu trabalho mais fácil.
                        </Paragraph>
                    </Box>
                    <Grid
                        columns={selectCardFeaturesColumn()}
                        rows={['1fr']}
                        gap="medium"
                    >
                        {features.map((f, i) => {
                            return <FeatureCard key={i} {...f} />
                        })}
                    </Grid>
                </Box>
            </Box>
            <TriangleRetangle style={{ transform: 'scale(-1,-1) translateY(0.4px)' }} />
        </Box>
    )
}

export default Features