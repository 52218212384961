import React, { useState, useContext } from 'react'
import produce from 'immer'
import { Box, Button, ResponsiveContext } from 'grommet'
import { motion } from 'framer-motion'
import { FormPreviousLink, FormNextLink } from 'grommet-icons'
import styles from './smartphoneCards.module.scss'

import {ReactComponent as Interface1} from '../vectors/interface_mobile1.svg'
import {ReactComponent as Interface2} from '../vectors/interface_mobile2.svg'

function SmartphoneCards(props) {

    const viewport = useContext(ResponsiveContext);

    const [activeVariants, setActiveVariants] = useState(['stay', 'front'])

    const mobileCardsVariants = {
        stay: {
            rotate: [0, 0, 21],
            translateX: viewport.includes('small') ? ["-2vw", "-27vw", "2vw"] : ["-2vw", "-12vw", "2vw"],
        },
        front: {
            rotate: [21, 21, 0],
            translateX: viewport.includes('small') ? ["2vw", '27vw', "-2vw"] : ["2vw", '12vw', "-2vw"],
        },
        stayIndex: {
            zIndex: 1,
            transition: {
                delay: 0.66,
            },
        },
        frontIndex: {
            zIndex: 2,
            transition: {
                delay: 0.66,
            },
        },

    }

    const handleMoveCard = (direction = 'next') => {
        const index = activeVariants.findIndex(f => f === 'front')
        setActiveVariants(produce(draft => {
            draft[index] = 'stay';
            if (direction === 'prev' || direction === 'previous') {
                if (index === 0) {
                    draft[activeVariants.length - 1] = 'front'
                } else {
                    draft[index - 1] = 'front'

                }
            } else {
                if (activeVariants.length === (index + 1)) {
                    draft[0] = 'front'
                } else {
                    draft[index + 1] = 'front'
                }
            }
        }))
    }

    return (
        <Box direction='row' align='center' width="100%">
            <Box align='center'>
                <Button
                    icon={<FormPreviousLink color="jet" size="large" />}
                    onClick={() => handleMoveCard('prev')}
                    hoverIndicator
                    className={styles.buttonContainer}
                    margin={{ horizontal: 'medium' }}
                />
            </Box>
            <Box className={styles.interfacesContainer} align="center" pad={{ vertical: 'medium' }}>
                <motion.div
                    animate={[activeVariants[0], activeVariants[0] === 'front' ? 'frontIndex' : 'stayIndex']}
                    variants={mobileCardsVariants}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    style={{ height: "100%", position: 'absolute' }}
                >
                    <Interface1 className={styles.interfaces} />
                </motion.div>
                <motion.div
                    animate={[activeVariants[1], activeVariants[1] === 'front' ? 'frontIndex' : 'stayIndex']}
                    variants={mobileCardsVariants}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    style={{ height: "100%", position: 'absolute' }}
                >
                    <Interface2 className={styles.interfaces} />
                </motion.div>
            </Box>
            <Box align='center'>
                <Button
                    icon={<FormNextLink color="jet" size="large" />}
                    onClick={handleMoveCard}
                    hoverIndicator
                    className={styles.buttonContainer}
                    margin={{ horizontal: 'medium' }}
                />
            </Box>
        </Box>
    )
}

export default SmartphoneCards