import React, { useContext } from 'react'
import { Box, Text, Paragraph, Image, ResponsiveContext } from 'grommet'
import { Deliver } from 'grommet-icons';
import { setViewportTerm } from '../utils';
import styles from './KeyFunction.module.scss'


function KeyFunction({location}) {
    const viewport = useContext(ResponsiveContext);

    return (
        <Box margin={{ vertical: 'xlarge' }}>
            <Box
                pad={{ horizontal: 'medium' }}
            >
                <Box
                    direction='row'
                    margin={{ horizontal: 'auto' }}
                    align="center"
                >
                    <Deliver
                        color='secondary'
                    />
                    <Text
                        color="secondary"
                        size={setViewportTerm(viewport, "small")}
                        margin={{
                            left: 'xsmall'
                        }}
                    >
                        Gestão de Delivery Robusta
                    </Text>
                </Box>
                <Text
                    className={styles.headerText}
                    as='h2'
                    size={setViewportTerm(viewport, "xlarge")}
                    color="primary"
                    textAlign='center'
                    margin={{ horizontal: 'auto', vertical: 'none', bottom: 'medium' }}
                >
                    Faça entregas de uma forma mais inteligente
                </Text>
                <Paragraph
                    textAlign='center'
                    alignSelf='center'
                    size={setViewportTerm(viewport, "large")}

                >
                    Crie rotas entre múltiplas filiais e pontos de entrega, o Cella calcula a melhor rota para você economizar recursos e tempo. Mais autonomia? Você também pode editar as rotas do seu jeito. Tudo isso também pode ser feito entre suas filiais e empresas.
                </Paragraph>
            </Box>
            <Box
                margin={{ top: 'medium', horizontal: viewport.includes('small') ? 'small' : 'xxlarge' }}
            >
                <Image
                    src={require('../assets/images/inteface_example_key_function.webp')}
                    className={styles.keyInterfaceImage}
                />
            </Box>
        </Box>
    )
}

export default KeyFunction