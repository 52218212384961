import React from 'react'
import Default from '../layouts/default'
import Layout from '../layouts/layout'
import AboutContent from '../components/aboutContent'

function sobre() {

    
    return (
        <Layout title="Sobre o Sistema | Cella">
            <Default
                header="Sobre o Sistema"
                commentHeader="Mais sobre o sistema Cella"
            >
                <AboutContent/>
            </Default>
        </Layout>
    )
}

export default sobre
//S.D.G.