import React, { useContext, useState } from 'react';
import { motion } from "framer-motion"
import { Box, ResponsiveContext, Text, Grid, TextInput, Button, ThemeContext } from 'grommet';
import Lottie from 'react-lottie';
import toast from 'react-hot-toast';
import emailjs from '@emailjs/browser';

import { validateEmail, setViewportTerm } from '../utils';
import styles from './heroPresentation.module.scss'
import generalStyles from '../general.module.scss'
import * as doubleWaveAnimation from '../lottie/wave_lines.json'
import * as explodeLinesAnimation from '../lottie/explode_lines.json'

import { ReactComponent as HeroInterfaceMobileIlustration } from '../vectors/hero_interface_mobile.svg'
import { ReactComponent as HeroInterfaceIlustration } from '../vectors/hero_interface.svg'


function HeroPresentation() {

    const viewport = useContext(ResponsiveContext);
    const theme = useContext(ThemeContext);
    const [email, onChangeEmail] = useState('');

    const handleChangeEmail = e => {
        onChangeEmail(e.target.value)
    }

    const handleKeyChangeEmail = e => {
        if (e.code === 'Enter') {
            handleSignEmail(e);
        }
    }
    const handleSignEmail = () => {
        if (validateEmail(email)) {
            emailjs.send('service_dhw6l7k', 'template_b8lbzqu', { email, message: "Desejo conhecer o Cella" }, 'Nillr9mrr63dSImV3')
                .then(res => {
                    toast.success("Seu pedido foi enviado com sucesso! Em breve nossa equipe entrará em contato com você. Obrigado.", {
                        duration: 10000,
                        style: {
                            width: 600,
                            maxWidth: '80vw',
                            color: theme.global.colors['light-1'],
                            background: theme.global.colors['success']
                        },
                        ariaProps: {
                            role: 'status',
                            'aria-live': 'polite',
                        },
                    })
                }, (error) => {
                    console.log(error, 'errror')
                    toast.error("Mensagem não enviada. Tente novamente ou utulize outros meios para contactar-nos.", {
                        duration: 10000,
                        icon: '😢',
                        style: {
                            width: 600,
                            maxWidth: '80vw',
                            // color: theme.global.colors['light-1'],
                            // background: theme.global.colors['success']
                        },
                        ariaProps: {
                            role: 'status',
                            'aria-live': 'polite',
                        },
                    })
                })
        } else {
            toast("Email inválido.", {
                duration: 6000,
                icon: '⚠️',
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            })
        }
    }

    const doubleWaveAnimationOption = {
        loop: true,
        autoplay: true,
        animationData: doubleWaveAnimation,
    }
    const explodeLinesAnimationOption = {
        loop: true,
        autoplay: true,
        animationData: explodeLinesAnimation,
    }

    return (
        <Grid
            columns={(!viewport.includes('large')) ? ['1fr'] : ['1.1fr', '1fr']}
            className={generalStyles.container}
            areas={
                !viewport.includes('large')
                    ? [
                        ['ilustrations'],
                        ['info'],
                    ]
                    : [
                        ['info', 'ilustrations']
                    ]
            }
            margin={
                {
                    vertical: 'large',
                    bottom: 'xxxlarge'
                }
            }
            gap={"small"}
        >
            {/* <div style={{position: 'fixed', left: 0, top: 0, background: 'white', color: 'black', zIndex: 9999999999}}>
                {viewport}
            </div> */}
            <Box animation={["fadeIn", "slideDown"]} gridArea="info" justify='center'>
                <Text size={setViewportTerm(viewport, "small")}>
                    Sistema Integrado de Vendas e Distribuição Georreferenciada.
                </Text>
                <Text as="h1" size={setViewportTerm(viewport, "xxlarge")} margin={{ bottom: 'xsmall' }}>
                    Potencialize suas
                    <Text size={setViewportTerm(viewport, "xxlarge")} color="thirdLight"> vendas <Text size={setViewportTerm(viewport, "xxlarge")} color="white">e</Text> entregas</Text>,
                    <Text size={setViewportTerm(viewport, "xxlarge")} color="secondaryLight"> sem complicação</Text>
                </Text>
                <Text size={setViewportTerm(viewport, "regular")} margin={{ top: 'small' }}>
                    Tenha controle de múltiplas filiais, crie rotas de entrega inteligentes e otimize suas vendas. Tudo isso em qualquer lugar e em uma interface fácil de aprender e manipular.
                </Text>
                <Box
                    animation="fadeIn"
                    direction='row'
                    gap="medium"
                    margin={{ top: 'large' }}
                    align="center"
                >
                    <TextInput
                        placeholder="Email institucional"
                        aria-placeholder='Email institucional'
                        size="xsmall"
                        name="email"
                        type="email"
                        plain
                        value={email}
                        onChange={handleChangeEmail}
                        onKeyUp={handleKeyChangeEmail}
                        style={{
                            boxShadow: '0 2px 4px rgba(32,32,32,0.16)'
                        }}
                    />
                    <Button
                        color={'secondary'}
                        primary
                        label={viewport === 'small' ? "Conheça" : "Conheça o Sistema"}
                        onClick={handleSignEmail}
                        style={{ height: 52 }}
                    />
                </Box>
            </Box>
            <Box
                gridArea="ilustrations"
                className={`${styles.ilustrationsContainer}`}
                animation={["fadeIn", "slideUp"]}
                margin={{
                    bottom: !viewport.includes('large') ? 'large' : undefined
                }}
            >
                <Box className={styles.containerIlustrations}>
                    <Box
                        className={styles.explodeLinesAnimationContainer}
                    >
                        <Lottie
                            options={explodeLinesAnimationOption}
                            speed={0.6}
                        />
                    </Box>
                    <Box
                        className={styles.doubleWaveAnimationContainer}
                    >
                        <Lottie
                            options={doubleWaveAnimationOption}
                            speed={0.4}
                        />
                    </Box>
                    <motion.div
                        initial={{ y: 0, rotate: 0.02 }}
                        animate={{ y: -10, rotate: 0.03 }}
                        transition={{
                            ease: "easeInOut",
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 4
                        }}
                    >
                        <HeroInterfaceIlustration className={styles.heroInterfaceIlustration} />
                    </motion.div>
                    <motion.div
                        initial={{ y: 0, rotate: 0.02 }}
                        animate={{ y: 10, rotate: 0.03 }}
                        transition={{
                            ease: "easeInOut",
                            repeat: Infinity,
                            repeatType: 'mirror',
                            duration: 4
                        }}
                    >
                        <HeroInterfaceMobileIlustration className={styles.heroInterfaceMobileIlustration} />
                    </motion.div>
                </Box>
            </Box>
        </Grid>
    )
}

export default HeroPresentation