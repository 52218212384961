import React  from 'react'
import { Box } from 'grommet'


import Nav from '../layouts/nav'
import HeroPresentation from '../components/heroPresentation'
import styles from "./HeroHome.module.scss";

function HeroHome() {

  return (
    <Box background={"heroHome"} as="section" className={styles.heroBackground} >
      <Box className={styles.interfaceComponents} >
        <Nav />
        <HeroPresentation />
      </Box>
    </Box>
  )
}

export default HeroHome