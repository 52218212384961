import React, { useContext, useEffect, useRef, useState, useMemo } from 'react'
import produce from 'immer'
import { motion, useTransform, useMotionValue } from 'framer-motion'
import { Box, ResponsiveContext, Text, Paragraph, Image } from 'grommet'
import AppContext from '../contexts/appContext';
import { setViewportTerm } from '../utils';
import styles from './desktopPresentation.module.scss'
import generalStyles from '../general.module.scss'

import BlackScreenImage from '../assets/images/black-desk-interface.webp'
import whiteScreenImage from '../assets/images/white-desk-interface.webp'

function DesktopPresentation() {

    const viewport = useContext(ResponsiveContext)
    const ref = useRef(null)
    const [scrollDimensions, setScrollDimensions] = useState({
        scrollHeight: 0,
    })
    const { mainScroll } = useContext(AppContext);


    const percentY = useMotionValue(0)
    const y = useMotionValue(0)


    const frameInterval = useMemo(() => {
        if (ref.current && scrollDimensions.scrollHeight > 0) {
            const dimensions = ref.current.getBoundingClientRect();
            const height = ref.current.offsetHeight + 200;

            let topPosition = mainScroll.y + dimensions.y;
            let bottomPosition = topPosition + height;

            return [topPosition / scrollDimensions.scrollHeight, bottomPosition / scrollDimensions.scrollHeight]
        } else {

            return [0, 1]
        }

    }, [mainScroll.y, ref])

    useEffect(() => {
        const isBrowser = () => typeof window !== "undefined"
        if (isBrowser()) {
            if (mainScroll.ref) {
                setScrollDimensions(produce(draft => {
                    draft.scrollHeight = mainScroll.ref.scrollHeight;
                }))
            }
            if (mainScroll.ref) {
                return window.addEventListener('resize', e => {
                    setScrollDimensions(produce(draft => {
                        draft.scrollHeight = mainScroll.ref.scrollHeight;
                    }))
                })
            }
        }
    }, [mainScroll.ref])

    useEffect(() => {
        percentY.set(mainScroll.percentY)
        y.set(mainScroll.y)
    }, [mainScroll])

    const firstFrame = useTransform(percentY, frameInterval, viewport.includes('small') ? ['-2em', '2em'] : ['8vw', '4vw'])
    const secondFrame = useTransform(percentY, frameInterval, viewport.includes('small') ? ['2em', '-2em'] : ['9vw', '13vw'])

    return (
        <Box
            as="section"
            align="center"
            justify='center'
            ref={ref}
            pad={{ bottom: 'small' }}
            overflow={{ horizontal: 'hidden', vertical: 'hidden' }}
            height={
                viewport.includes('small')
                    ? { min: '100px', max: '1200px' }
                    : { height: '100vh', min: '800px', max: '1500px' }
            }
        >
            <Box
                className={generalStyles.containerNoMargin}
                direction={viewport.includes('small') ? "column-reverse" : "row"}
                gap="medium"
                align="center"
                height={
                    viewport.includes('small')
                        ? { min: '100px', max: '1200px' }
                        : { height: '100vh', min: '800px', max: '1200px' }
                }
            >
                <Box
                    width={viewport.includes('small') ? "100%" : "40%"}
                >
                    <Text
                        as={'h3'}
                        size={setViewportTerm(viewport, 'large')}
                        color="primary"
                        align="center"
                        textAlign='center'
                    >
                        Interfaces Belas e Intuitivas
                    </Text>
                    <Paragraph size={setViewportTerm(viewport, 'large')} textAlign="center">
                        As interfaces do Cella foram desenvolvidas utilizando tecnicas de experiência do usuário, objetivando a facilidade de uso e uma curta curva de aprendizado. Com páginas claras e relaxantes, nosso objetivo é entregar uma boa experiência e evitar o cansaço visual mesmo depois de longas jornadas de uso.
                    </Paragraph>
                </Box>
                <Box
                    className={styles.interfacesContainer}
                    justify="center"
                    height={{ height: viewport.includes('small') ? '16em' : '35vw', max: '1000px' }}
                    align={viewport.includes('small') ? 'center' : ''}
                    width={viewport.includes('small') ? '100%' : 'unset'}
                >
                    <motion.div
                        className={styles.interfaceFrame}
                        style={{
                            width: viewport.includes('small') ? '16em' : '38em',
                            height: viewport.includes('small') ? '9em' : '23em',
                            transformPerspective: 500,
                            skewY: '3deg',
                            skewX: '-3deg',
                            rotateX: '4deg',
                            rotateY: '-15deg',
                            translateX: firstFrame,
                            translateY: viewport.includes('small') ? 0 : 0
                        }}
                    >
                        <Box border={{ size: "small", color: 'raisinBlack' }} background="linear-diagonal-black-gradient" pad={viewport.includes('small') ? "xsmall" : "small"} >
                            <Image src={BlackScreenImage} margin={{ bottom: 'none' }} />
                        </Box>
                    </motion.div>
                    <motion.div
                        className={styles.interfaceFrame}
                        style={{
                            width: viewport.includes('small') ? '16em' : '33em',
                            height: viewport.includes('small') ? '9em' : '19em',
                            transformPerspective: 500,
                            skewY: '3deg',
                            skewX: '-3deg',
                            rotateX: '4deg',
                            rotateY: '-15deg',
                            translateX: secondFrame,
                            translateY: viewport.includes('small') ? '2em' : '5em'
                        }}
                    >
                        <Box border={{ size: "small", color: 'platinum' }} background="linear-diagonal-white-gradient" pad={viewport.includes('small') ? "xsmall" : "small"} >
                            <Image src={whiteScreenImage} margin={{ bottom: 'none' }} />
                        </Box>
                    </motion.div>
                </Box>
            </Box>
        </Box>
    )
}

export default DesktopPresentation