import React, { useState, useContext, useEffect, useRef } from 'react'
import { useIMask } from 'react-imask';
import {
    Box,
    Grid,
    Button,
    Form,
    FormField,
    TextInput,
    ThemeContext,
    Grommet,
    TextArea
} from 'grommet';
import { deepMerge } from 'grommet/utils'
import { masks } from '../constants'
import toast from 'react-hot-toast';
import emailjs from '@emailjs/browser';

function ContactForm({ message }) {

    const [value, setValue] = useState()

    const theme = useContext(ThemeContext);
    const form = useRef(null)

    const {
        ref: phoneRef,
    } = useIMask({
        mask: [{ mask: masks.tel }, { mask: masks.phone }],
        dispatch: (appended, dynamicMasked) => {
            const number = (dynamicMasked.value + appended).replace(/\D/g, "");

            if (number.length > 10) {
                return dynamicMasked.compiledMasks[1];
            }

            return dynamicMasked.compiledMasks[0];
        },
    })
    const {
        ref: docRef,
    } = useIMask({
        mask: [{ mask: masks.cpf }, { mask: masks.cnpj }],
        dispatch: (appended, dynamicMasked) => {
            const number = (dynamicMasked.value + appended).replace(/\D/g, "");

            if (number.length > 11) {
                return dynamicMasked.compiledMasks[1];
            }

            return dynamicMasked.compiledMasks[0];
        },
    })

    const customTheme = {
        formField: {
            label: {
                font: {
                    family: 'Roboto'
                },
                size: 'small',
            },
            hover: {
                border: {
                    color: 'primary'
                }
            }
        },
    }

    useEffect(() => {
        setValue({ ...value, message })
    }, [message])

    const sendMail = () => {
        emailjs.sendForm('service_dhw6l7k', 'template_b8lbzqu', form.current, 'Nillr9mrr63dSImV3')
            .then((result) => {
                console.log(result.text);
                toast.success("Sua mensagem foi enviada com sucesso! Em breve nossa equipe entrará em contato com você. Obrigado.", {
                    duration: 10000,
                    style: {
                        width: 600,
                        maxWidth: '80vw',
                        color: theme.global.colors['light-1'],
                        background: theme.global.colors['success']
                    },
                    ariaProps: {
                        role: 'status',
                        'aria-live': 'polite',
                    },
                })
            }, (error) => {
                console.log(error, 'errror')
                toast.error("Mensagem não enviada. Tente novamente ou utulize outros meios para contactar-nos.", {
                    duration: 10000,
                    icon: '😢',
                    style: {
                        width: 600,
                        maxWidth: '80vw',
                        // color: theme.global.colors['light-1'],
                        // background: theme.global.colors['success']
                    },
                    ariaProps: {
                        role: 'status',
                        'aria-live': 'polite',
                    },
                })
            });
    }

    return (
        <Grommet theme={deepMerge(theme, customTheme)}>
            <Form
                onSubmit={sendMail}
                ref={form}
            >
                <Grid
                    columns={['1fr', '1fr']}
                    gap="medium"
                >
                    <FormField label="Nome" name="name">
                        <TextInput plain aria-label="Nome" name="name" type="name" placeholder="Pessoa/Empresa" focusIndicator={false} />
                    </FormField>

                    <FormField label="Email" name="email" required>
                        <TextInput plain aria-label="email" name="email" type="email" placeholder="email@minhaempresa.com.br" focusIndicator={false} />
                    </FormField>

                    <FormField label="Telefone" name="phone">
                        <TextInput ref={phoneRef} plain aria-label="phone" name="phone" focusIndicator={false} placeholder="12 9987-6543" />
                    </FormField>

                    <FormField label="CNPJ/CPF" name="subject">
                        <TextInput ref={docRef} plain aria-label="CNPJ/CPF" name="doc" focusIndicator={false} placeholder="Opcional" />
                    </FormField>

                    <FormField label="Mensagem" name="message" required style={{ gridColumn: "span 2" }}>
                        <TextArea value={value && value.message} aria-label="Mensagem" name="message" type="message" placeholder="Desejo uma demonstração do sistema." onChange={e => setValue(e.target.value)} />
                    </FormField>

                    <Box direction="row" justify="between" margin={{ top: 'medium' }}>
                        <Button type="submit" label="Enviar" color="primary" primary />
                    </Box>
                </Grid>
            </Form>
        </Grommet>
    );
}

export default ContactForm