
const sizeByViewport = (viewport, size) => {
    if (viewport !== "large" && viewport !== "small") {
        return `${viewport}-${size}`
    } else return size;
}

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function setViewportTerm(viewport, size, sizeTerm = 'small', term = null,) {
    if(viewport && viewport.includes) {
        return (term || viewport.includes('small')) ? `${sizeTerm}-${size}` : size
    }
}
export {
    sizeByViewport,
    validateEmail,
    setViewportTerm
}