import { ReactComponent as Tent } from '../vectors/Tent.svg'
import { ReactComponent as Certificate } from '../vectors/certificate.svg'
import { ReactComponent as Package } from '../vectors/Package.svg'
import { ReactComponent as Link } from '../vectors/Link.svg'
import { ReactComponent as Mobile } from '../vectors/Mobile.svg'
import { ReactComponent as Shape } from '../vectors/Shape.svg'

const features = [
    {
        icon: Tent,
        title: 'Ponto de Vendas',
        description: 'Crie orçamentos, realize vendas e programe pedidos. Além disso, dispomos de um conjunto de funcionalidades para flexibilizar suas operações. Tudo sem perder a simplicidade de um PDV.'
    },
    {
        icon: Certificate,
        title: 'Notas Fiscais',
        description: 'Configure apenas uma vez os dados de suas empresas e filiais, então emita suas notas de forma natural e automatizada.'
    },
    {
        icon: Package,
        title: 'Gestão de Estoque',
        description: 'Cadastre, administre e organize seus produtos, um de cada vez ou utilize o nosso poderoso cadastramento em lote. No qual você pode inserir vários produtos a partir do XML de uma nota fiscal ou importa-los através de um arquivo CSV.'
    },
    {
        icon: Link,
        title: 'Sistema Integrado',
        description: 'Tenha controle e compartilhe as informações, pedidos, armazéns, clientes e produtos entre filiais. Você também pode restringir os dados que cada filial poderá acessar e manipular. Cada uma delas pode possuir seu próprios caixas, PDV, notas fiscais e mais.'
    },
    {
        icon: Mobile,
        title: 'Multiplataforma',
        description: 'O Cella disponibiliza um aplicativo para os entregadores. O ERP é completamente responsivo e pode ser usado em qualquer lugar.'
    },
    {
        icon: Shape,
        title: 'Seja Notificado',
        description: 'O Cella te informa. Seja notificado sempre que um pedido for feito, aceito (ou negado), em atualizações de notas fiscais, quando o estoque mínimo de um produto for atingido e mais.'
    },
]

export {
    features
}